/*
 *
 *
 *
 */

import { Buffer } from 'buffer';

// test if string is valid email
export const isEmail = (email: string) =>
  // @ts-ignore : regex is complicated
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

// get a token payload decoded
export const decodeTokenPayload = (token: string) => {
  if (!token) return;

  const parts = token.split('.');

  if (parts.length !== 2) return;

  try {
    let token = parts[0].replace(/-/g, '+').replace(/_/g, '/');

    const pad = token.length % 4;
    if (pad) {
      if (pad === 1) {
        console.error('token is wrong length');
        return {};
      }
      token += new Array(5 - pad).join('=');
    }

    return JSON.parse(Buffer.from(token, 'base64').toString());
  } catch (err) {
    console.error('token decoding error:', err);
    return {};
  }
};
