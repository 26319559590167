/**
 *
 *
 *
 */

import { createContext, useCallback, useContext, useState } from 'react';
import { useLocalStorage } from '../hooks/use-local-storage';
import { nanoid } from 'nanoid';
import appConfig from '../config/app';
import { LanguageContext } from './language';
import { randomChar } from '../helpers/strings';
import { decodeTokenPayload } from '../helpers/user';

// ----------------------------------------------------------------------------
// types

export interface User {
  id: string;
  email: string;
  phone: string;
  language: 'nl' | 'en';
  admin: boolean;
}

export type UserContextType = {
  loading: boolean;
  session: string;
  token: string | undefined;
  user: {
    id: string;
    name: string;
    email: string;
    phone: string;
  } | undefined;
  admin: boolean;
  login: (
    username: string,
    password: string,
  ) => Promise<boolean | number>;
  logout: () => Promise<boolean>;
  register: (email: string, name?: string, phone?: string) => Promise<boolean>;
};
export type UserProviderProps = {
  children?: React.ReactNode;
};

// ----------------------------------------------------------------------------
// initial context
export const UserContext = createContext<UserContextType>({
  loading: false,
  session: '',
  token: undefined,
  user: undefined,
  admin: false,
  login: async (username, password) => false,
  logout: async () => false,
  register: async (email) => false,
});

// ----------------------------------------------------------------------------
// context provider
export const UserProvider: React.FC<UserProviderProps> = (props) => {
  // props
  const { children } = props;

  // hooks
  const storage = useLocalStorage();

  // context
  const { setLanguage, current: currentLang } = useContext(LanguageContext);

  // loading state
  const [loading, setLoading] = useState<boolean>(false);

  // session
  const [session] = useState<string>(() => {
    let sess = storage.load(appConfig.localStorageKeys.session);
    if (!sess) {
      sess = nanoid(); // 21 chars long
      storage.save(appConfig.localStorageKeys.session, sess);
    }
    return sess;
  });

  // user data
  const [user, setUser] = useState< | undefined>(
    undefined,
  );

  // user's role
  const [admin, setAdmin] = useState<boolean>(false);

  // token
  const [token, setToken] = useState<string | undefined>(() => {
    const tok = storage.load(appConfig.localStorageKeys.token);
    if (!tok) return undefined;
    const tk = tok.slice(0, -1);
    const pl = decodeTokenPayload(tk);
    setAdmin(pl.admin || false);
    return tk;
  });

  // login user
  const login = useCallback(
    async (email: string, password: string) => {
      try {
        setLoading(true);

        // request body
        const rawbody: any = {
          email,
          password,
          session,
        };

        const body = JSON.stringify(rawbody);

        // request
        const response = await fetch(appConfig.api.baseUrl + '/users/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: body,
        });

        // bad request
        if (response.status === 400) {
          return 400;
        }

        // wrong user/pass
        if (response.status === 401) {
          return 401;
        }

        // need to verify emailaddress
        if (response.status === 403) {
          return 403;
        }

        // something went wrong
        if (response.status !== 200) {
          return 500;
        }

        const data = await response.json();

        // token?
        if (!data['x-token']) {
          return 500;
        }

        setToken(data['x-token']);
        storage.save(
          appConfig.localStorageKeys.token,
          data['x-token'] + randomChar(),
        );

        // user data?
        if (data.user) {
          setUser(data.user);
        }

        // // is administrator?
        if (data.user.admin) {
          setAdmin(true);
        } else {
          setAdmin(false);
        }

        // language
        if (currentLang !== data.user.language) {
          setLanguage(data.user.language);
        }

        setLoading(false);
        return 200;
      } catch (err) {
        setLoading(false);
        console.error(err);
        return 500;
      }
    },
    [session, storage, setLanguage, currentLang],
  );

  // logout user
  const logout = useCallback(async () => {
    try {
      // setLoading(true);
      setUser(undefined);
      setToken(undefined);
      setAdmin(false);
      storage.remove(appConfig.localStorageKeys.token);

      // setLoading(false);
      return true;
    } catch (err) {
      console.error(err);
      // setLoading(false);
      return false;
    }
  }, [storage]);


  // register a user account
  const register = useCallback(async ( email: string, name?: string, phone?: string) => {
    try {

      const response = await fetch(appConfig.api.baseUrl + '/users', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          email: email,
          name: name,
          phone: phone,
          language: currentLang,
        }),
      });

      if( response.status === 201 ) {
        return true;
      } else {
        return false
      }
    } catch( err) {
      console.error("register() error", err);
      return false;
    }
  }, [currentLang]);

  // jsx and provider
  return (
    <UserContext.Provider
      value={{
        loading,
        session,
        token,
        user,
        admin,
        login,
        logout,
        register,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
// ----------------------------------------------------------------------------
// End
