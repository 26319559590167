/**
 *
 */

import React from 'react';

// use localstorage hook
export const useLocalStorage = () => {
  // test if localstorage is available
  const localStorageAvailable: () => boolean = () => {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  // load from localstorage
  const load = React.useCallback((key: string, fallback?: string) => {
    if (localStorageAvailable()) {
      try {
        return localStorage.getItem(key) || undefined;
      } catch (e) {
        console.error(e);
        return fallback || undefined;
      }
    }
    return fallback || undefined;
  }, []);

  // save to localstorage
  const save = React.useCallback((key: string, value: string) => {
    if (localStorageAvailable()) {
      try {
        localStorage.setItem(key, value);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return false;
  }, []);

  // remove from localstorage
  const remove = React.useCallback((key: string) => {
    if (localStorageAvailable()) {
      try {
        localStorage.removeItem(key);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return false;
  }, []);

  // make functions available
  return {
    load,
    save,
    remove,
  };
};
