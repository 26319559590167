export const nederlands = {
  common: {
    notFound: {
      title: 'Deze pagina bestaat niet',
      subTitle: 'Terug naar de startpagina',
    },
    signUpForm: {
      loginTitle: 'Login',
      forgotPasswordTitle: 'Wachtwoord vergeten',
      registerTitle: 'Registeer Account',
      emailLabel: 'E-Mail',
      passwordLabel: 'Wachtwoord',
      nameLabel: 'Naam',
      phoneLabel: 'Telefoonnummer',
      loginButton: 'Login',
      requestPasswordButton: 'Email Wachtwoord',
      registerButton: 'Creer Account',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      registerAccountLink: 'Registreer account',
      backToLogin: 'Terug naar Inloggen',
    },
  },
  myAccount: {
    myBingoCards: {
      header: 'Mijn Bingokaarten',
      listItemCardId: 'Kaartnummer:',
      noCardsFound: 'Geen bingokaarten gevonden',
    },
    myBingoGames: {
      header: 'Mijn Bingospellen',
      noGamesFound: 'Uw heeft (nog) geen bingospellen',
    },
    mySettings: {
      header: 'Account Instellingen'
    }
  },
  myBingoGame: {
    settingsHeader: 'Spel Instellingen',
    cardsHeader: 'Bingokaarten',
    playHeader: 'Speel Bingo',
  }, 
  bingo: {
    bingoSettings: {
      name: "Naam",
      placeholderName: "Uw Bingo Naam",
      type: "Type",
      typeNumber: "Nummer Bingo",
      typeText: "Tekst Bingo",
      typeMusic: "Muzieknummer Bingo",
      typeVideo: "Videonummer Bingo",
      typePhoto: "Foto Bingo",
      variant: "Variant",
      variant5x5: "5x5",
      variant3x3: "3x3",
      figures: "Bingo Figuren",
    },
  }
}
export default nederlands;