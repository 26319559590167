import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { 
  Backdrop, 
  CircularProgress, 
  createTheme, 
  CssBaseline, 
  ThemeProvider 
} from '@mui/material';
import { LanguageProvider } from './context/language';

// mui fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { UserProvider } from './context/user';



// lazy load app
const App = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <ThemeProvider 
          theme={createTheme({
            palette: {
              // mode: 'dark',
              primary: {
                main: '#b71c1c',
              },
              secondary: {
                main: '#546e7a',
              },
            },
            components: {
              MuiButton: {
                defaultProps: {
                  variant: "contained",
                }
              },
              MuiIconButton: {
                defaultProps: {
                }
              }
            }
          })}
        >
          <LanguageProvider>
            <React.Suspense fallback={
              <Backdrop open={true}><CircularProgress color="inherit" /></Backdrop>
            }>
              <CssBaseline />
              <App />
            </React.Suspense>
          </LanguageProvider>
        </ThemeProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);
