/**
 *
 */

import React, { useState } from 'react';
import merge from 'lodash/merge';
import { useLocalStorage } from '../hooks/use-local-storage';
import appConfig from '../config/app';

// import languages
import en from '../translations/english';
import nl from '../translations/nederlands';


// languages object
const languages = {
  nl: nl as Translation,
  en: en as Translation,
};

// types
export type LanguageContextType = {
  text: Translation;
  current: string;
  setLanguage: (lang: AvailableLanguages) => void;
};

export type LanguageProviderProps = {
  children?: React.ReactNode;
  defaultLanguage?: string;
};

export type AvailableLanguages = 'nl' | 'en';

export interface Translation {
  [k: string]: string | Translation | any;
}

// context
export const LanguageContext: React.Context<LanguageContextType> =
  React.createContext({
    text: {},
    current: 'nl',
    setLanguage: (lang: AvailableLanguages) => {},
  });

// context provider
export const LanguageProvider: React.FC<LanguageProviderProps> = (props) => {
  const { children, defaultLanguage } = props;

  const { load, save } = useLocalStorage();

  const initialLanguage = (load(
    appConfig.localStorageKeys.language,
    defaultLanguage,
  ) || 'nl') as AvailableLanguages;

  const [current, setCurrent] = useState<AvailableLanguages>(initialLanguage);
  const [text, setText] = useState<Translation>(merge({}, languages['nl'], languages[initialLanguage]));

  // change the language
  const setLanguage = React.useCallback(
    (lang: AvailableLanguages) => {
      if (!lang) return;
      setText(merge({}, languages['nl'], languages[lang]));
      setCurrent(lang);
      save(appConfig.localStorageKeys.language, lang);
    },
    [save],
  );

  return (
    <LanguageContext.Provider value={{ text, current, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
