/*



*/

// generate a (sortof) random letter
export const characters =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
export const randomChar = () =>
  characters[Math.floor(Math.random() * characters.length)];


// Capilized first letter
export const capitalize = (str: string) => str && str[0].toUpperCase() + str.substring(1);


// format a date

export const toShortTime = (time: string | number) => {
  const t = new Date(time);

  // valid date/time
  if( !time || t.toString() === "Invalid Date" ) {
    return time.toString();
  }

  const formatted = t.toLocaleString(undefined, {timeStyle: 'short'}) + " " + t.toLocaleString(undefined, {dateStyle: 'short'})
  return formatted;
}

export const formatNumber = (number: number) => new Intl.NumberFormat().format(number);