export const appConfig = {

  localStorageKeys: {
    language: 'language', // key of the cookie of the language setting
    session: 'session',
    token: 'token',
  },

  api: {
    baseUrl: 'http://172.16.32.240:20202'
  }

}
export default appConfig;